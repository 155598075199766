<template >
  <v-container >
    <template >
      <v-card v-if="orders.length > 0" class="mx-auto mt-3" max-width="1264" min-height="84vh" elevation="4"  >
        <div class="headline font-weight-medium pediidos--text  pl-5 pt-10 pb-5 " >{{$t('order.wellcome_title')}}</div>
        <v-list three-line>
          <template v-for="(order, index) in orders">
            <v-list-item :key="order.date_send">
              <v-list-item-avatar size="7vh"  >
                <v-img :src="order.shop.picture"></v-img>
              </v-list-item-avatar>
              <v-list-item-content class="align-center">
                <v-list-item-title>ID: {{order.id}}</v-list-item-title>
                <v-list-item-title v-text="order.shop.name"></v-list-item-title>
                <v-list-item-subtitle class="pediidos--text">{{order.date_send | formatDateShort}}</v-list-item-subtitle>
                <v-list-item-subtitle>{{$t('place')}}: {{order.user_address.address_alias}}</v-list-item-subtitle>
                <v-list-item-subtitle>{{$t('order.estimated_time')}}: {{order.time | formatDate}}</v-list-item-subtitle>
                <OrderDetail :oi="order.id" :si="order.shop.id" :ui="user.user_id" ></OrderDetail>
              </v-list-item-content>
              <v-list-item-action class="align-center">
                <v-list-item-title>{{order.total | formatCurrency}}</v-list-item-title>
                <v-list-item-action-text>{{order.total_quanty_products}} {{$t('item')}}</v-list-item-action-text>
                <v-icon :color="circleStatus(order.status_order.id)">mdi-circle</v-icon>
                <v-list-item-action-text>{{statusOrder(order.status_order.id)}}</v-list-item-action-text>
              </v-list-item-action>
            </v-list-item>
            <v-divider v-if="index < orders.length - 1" :key="index"></v-divider>
          </template>
        </v-list>
        <div class="text-center  py-10">
          <v-pagination v-model="currentPage" :length="totalPages" total-visible="7" circle color="pediidos" ></v-pagination>
        </div>
      </v-card>
      <v-card v-else class="mx-auto mt-3 text-center align-center justify-center " max-width="1264" min-height="75vh" elevation="4" >
        <v-icon size="250" style="margin-top: 80px;" >mdi-cart-off</v-icon>
        <p class=" my-5" >{{$t('order.msg_empty_ordes')}}</p>
        <v-btn outlined large rounded color="pediidos" @click="goShoping()" >{{$t('cart.go_to_shoping')}}</v-btn>
      </v-card>
    </template>
  </v-container>
</template>

<script type="text/javascript">
  import OrderDetail from '@/components/OrderDetail.vue';
  import Api from '@/helpers/api.js';
  import moment from '@/helpers/DateUtils.js';
  import Utils from '@/helpers/Utils.js';
  import { mapMutations, mapState } from 'vuex';
  export default {
    name: 'Orders-list',
    components: { OrderDetail },
    data() {
      return {
        orders: [],
        totalPages: 0,
        currentPage: 1,
      }
    },
    filters: {
        formatDateShort(date) {
            return moment.formatDate(date,'YYYY-MM-DDTHH:mm', 'MMM DD, hh:mm a');
        },
        formatDate(date) {
            return moment.formatDate(date,'YYYY-MM-DD', 'DD MMMM YYYY');
        },
        formatCurrency(currency) {
            return Utils.formatCurrency(currency);
        },
    },
    watch: {
      currentPage(value) {
          this.getOrders(value);
      },
    },
    async mounted(){
      this.getOrders(this.currentPage);
    },
    methods: {
      ...mapMutations(['APP_LOADER','SET_USER',]),
      async getOrders(page) {
        this.orders = []
        this.APP_LOADER(true);
        let data = {
          user_id: this.user.user_id,
          status_ids: [1,2,3,4,5,6],
          page: page,
          offset: 5
        }
        Api.post('orders/list/status/',data).then((result)=> {
          if(result.status < 300) {
            this.orders = result.data.data;
            this.currentPage = parseInt( result.data.pagination.actual_page );
            this.totalPages = parseInt( result.data.pagination.num_pages );
          }
        }).catch((error)=> {
          console.log('This is error ',error);
        }).finally(()=> {
          this.APP_LOADER(false);
        })
      },
      goShoping(){
        this.$router.push('/home');
      },
      circleStatus(status) {
        return Utils.colorcircleStatus(status);
      },
      statusOrder(status) {
        return this.$t( Utils.statusOrders(status) );
      },
    },
    computed: {
      ...mapState(['user',])
    },
  }
</script>
<style type="text/css" scoped>
</style>