import moment from 'moment';
import * as constants from '@/constants';

moment.locale(constants.LOCALE);
export default {
    formatDate(date, origen, format) {
        let date_timezone = (date + "-0500");  // Agregar a la fecha la zona horaria de la base de datos.
        let origen_timezone = (origen+":ss.SSSZ"); // Agregar al formato de origen la la zona horaria.
        // return moment(date_timezone, origen_timezone).format(format);
        return moment(date_timezone, origen_timezone).utcOffset(constants.TIMEZONE).format(format);
    },

    thisHour() {
        return moment().format('hh:mm:ss');
    },

    today() {
        return moment().format('YYYY-MM-DD');
    },

    thisMonth() {
        return moment().format('MM');
    },

    toUnix(currentDate, format) {
        var defaultFormatHour = 'YYYY-MM-DD HH:mm:ss';

        if (currentDate === null || currentDate === undefined) {
            console.warn('parameter current_date is missing');
            return false;
        }

        if (format === null || format === undefined) {
            defaultFormatHour = format;
        }

        let initDate = moment(currentDate, defaultFormatHour).unix();
        let horaAnterior = moment(currentDate, defaultFormatHour)
            .add(-72, 'h')
            .format(defaultFormatHour);
        let endDate = moment(horaAnterior, defaultFormatHour).unix();

        return {
            current: initDate,
            earlier: endDate,
        };
    },

    thisYear() {
        return moment().format('YYYY');
    },

    timeStamp() {
        const ts = moment().format('YYYYMMDDHHmmss');
        return ts;
    },

    toDefaultDateTime(el, format) {
        var defaultFormatHour = 'YYYY-MM-DD hh:mm:ss';
        if(format !== null && format !== undefined) {
            defaultFormatHour = format;
        }
        let result = moment(el, 'YYYYMMDDhhmmss').format(defaultFormatHour);
        return result;
    },

    addToDate(count, format, date) {
        // more info: https://momentjs.com/docs/#/manipulating/add/

        let defaultDate = null;
        const defaultFormatDate = 'YYYY-MM-DD';

        if (count === null || count === undefined) {
            console.warn('parameter count is missing');
            return false;
        }

        if (count <= 0) {
            console.warn('Error:', count, 'must be great than 0 (zero)');
            return false;
        }

        if (format === null || format === undefined) {
            console.warn('parameter format is missing');
            console.warn(
                'this parameter only allows the following formats: [years|y], [months|M], [weeks|w], [days|d]',
            );
            return false;
        }

        if (typeof format !== 'string') {
            console.warn('Error: format must be a string');
            console.warn(
                'this parameter only allows the following formats: [years|y], [months|M], [weeks|w], [days|d]',
            );
            return false;
        }

        if (date === null || date === undefined) {
            defaultDate = moment().format(defaultFormatDate);
        } else {
            if (typeof date !== 'string') {
                console.warn('Error: date must be a string');
                return false;
            }
            defaultDate = date;
        }

        return moment(defaultDate, defaultFormatDate)
            .add(count, format)
            .format(defaultFormatDate);
    },

    addToHour(count, format, hour) {
        // more info: https://momentjs.com/docs/#/manipulating/add/

        let defaultHour = null;
        const defaultFormatHour = 'HH:mm:ss';

        if (count === null || count === undefined) {
            console.warn('parameter count is missing');
            return false;
        }

        if (count <= 0) {
            console.warn('Error:', count, 'must be great than 0 (zero)');
            return false;
        }

        if (format === null || format === undefined) {
            console.warn('parameter format is missing');
            console.warn(
                'this parameter only allows the following formats: [hours|h],[minutes|m],[seconds|s] in 24h format',
            );
            return false;
        }

        if (typeof format !== 'string') {
            console.warn('Error: format must be a string');
            console.warn(
                'this parameter only allows the following formats: [hours|h],[minutes|m],[seconds|s] in 24h format',
            );
            return false;
        }

        if (hour === null || hour === undefined) {
            defaultHour = moment().format(defaultFormatHour);
        } else {
            if (typeof hour !== 'string') {
                console.warn('Error: hour must be a string');
                return false;
            }
            defaultHour = hour;
        }

        return moment(defaultHour, defaultFormatHour)
            .add(count, format)
            .format(defaultFormatHour);
    },
};
