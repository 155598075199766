<template>
  <v-dialog v-model="dialog" persistent fullscreen transition="dialog-top-transition" >
    <template v-slot:activator="{ on, attrs }">
      <v-btn outlined rounded color="pediidos" v-bind="attrs" v-on="on" >  {{$t('order.see_order')}}  </v-btn>
    </template>
    <v-card style="overflow-x: hidden;" >
      <v-toolbar dark color="pediidos"  >
        <v-toolbar-title  > {{$t('_order')}} #{{oi}} </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="dialog = !dialog" >{{$t('close')}}</v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card class="mx-auto my-4" max-width="1260" elevation="4" >
        <v-list-item>
          <v-list-item-content>
          <v-list-item-title class="headline font-weight-medium pediidos--text text-center mb-5 " >{{$t('_order')}} #{{oi}}</v-list-item-title>
          <v-row  class="text-center align-center" >
            <template v-for="(value, filed, index) in datesStates">
              <v-divider v-if=" index != 0 " :key="index"></v-divider>
              <v-col cols="12" md="2" class="pa-0" :key="value.description+index">
                <v-list class="pa-0 ">
                <v-avatar :color="value.background">
                  <v-icon dark x-large >{{value.textTab}}</v-icon>
                </v-avatar>
                <v-list-item >
                  <v-list-item-content class="pt-1">
                  <v-list-item-subtitle class="font-weight-bold " >{{formatDateShort(value.date)}}</v-list-item-subtitle>
                  <v-list-item-subtitle >{{value.description}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                </v-list>
              </v-col>
            </template>
          </v-row>
          </v-list-item-content>
        </v-list-item>
        <v-card-actions class="text-center align-center justify-center" >
          <v-alert border="top" color="red lighten-2" dark v-if="resumen.status_order.id === 3 ">
            {{$t('detail')}} {{$t('order.status_rejected')}}: {{resumen.motive_cancel}}
          </v-alert>
          <v-alert border="top" color="amber lighten-2" v-if="resumen.status_order.id === 5" >
            {{$t('detail')}} {{$t('order.status_to_cancel')}}: {{resumen.motive_cancel}}
          </v-alert>
          <v-alert border="top" color="red lighten-2" dark v-if="resumen.status_order.id === 6" >
            {{$t('detail')}} {{$t('order.status_canceled')}}: {{resumen.motive_cancel}}
          </v-alert>
        </v-card-actions>
      </v-card>
      <v-sheet class="mx-auto py-4 " max-width="1260" >
        <v-row >
          <v-col class="py-0 " >
            <v-card class="ma-0" min-height="165" elevation="4"  >
              <v-list-item >
              <v-list-item-content>
                <v-list-item-title class="headline mb-1">{{$t('shipping_data')}}</v-list-item-title>
                <v-list-item-subtitle class="subtitle-1" ><b>{{$t('name')}}:</b> {{client.name}}</v-list-item-subtitle>
                <v-list-item-subtitle class="subtitle-1" ><b>{{$t('address')}}:</b> {{client.address}}</v-list-item-subtitle>
                <v-list-item-subtitle class="subtitle-1" ><b>{{$t('detail')}}:</b> {{client.details}}</v-list-item-subtitle>
                <v-list-item-subtitle class="subtitle-1" ><b>{{$t('phone')}}:</b> {{client.phone}}</v-list-item-subtitle>
                <v-list-item-subtitle class="subtitle-1"><b>{{$t('order.estimated_time')}}:</b> {{resumen.time | formatDate}}</v-list-item-subtitle>
              </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col class="py-0 " >
          <v-card class="ma-0" min-height="165" elevation="4" >
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="headline mb-1">{{$t('cart.order_details')}}</v-list-item-title>
                <v-list-item-subtitle class="subtitle-1" ><b>{{$t('cart.seller')}}:</b> {{store.name}}</v-list-item-subtitle>
                <v-list-item-subtitle class="subtitle-1" ><b>{{$t('address')}}:</b> {{store.address}}</v-list-item-subtitle>
                <v-list-item-subtitle class="subtitle-1" ><b>{{$t('city')}}:</b> {{store.city}}</v-list-item-subtitle>
                <v-list-item-subtitle class="subtitle-1" ><b>{{$t('phone')}}:</b> {{store.phone}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
          </v-col>
        </v-row>
      </v-sheet>
      <v-card class="mx-auto my-4" max-width="1260" elevation="4" >
        <v-list two-line max-height="255" class="overflow-y-auto c-scroll" >
          <template v-for="(item, index) in detailOrder" >
            <v-list-item :key="item.product.product.name+index" >
              <v-list-item-avatar size="7vh" class="mt-0 mb-0" >
              <v-img :src="item.product.product.picture"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
              <v-card-text class="subtitle-2 text-uppercase text-ellipsis pa-0">{{item.product.product.name}}</v-card-text>
              <v-list-item-subtitle>{{$t('cart.price_init')}}: {{item.price_unit | formatCurrency}}</v-list-item-subtitle>
              <v-list-item-action-text>{{$t('cart.amount')}}: {{item.quanty}}</v-list-item-action-text>
              </v-list-item-content>
              <v-list-item-action>
              <v-list-item-title class="title" >{{item.subtotal | formatCurrency}}</v-list-item-title>
              </v-list-item-action>
            </v-list-item>
          <v-divider v-if="index < detailOrder.length - 1" :key="index"></v-divider>
          </template>
        </v-list>
      </v-card>
      <v-card class="mx-auto py-4" max-width="1260" elevation="4" >
        <v-row justify="space-between" class="text-center align-center" >
          <v-col >
            <v-list-item >
              <v-list-item-content>
                <v-list-item-title class="headline mb-2"><b>{{$t('cart.subtotal')}}:</b> {{resumen.subtotal | formatCurrency }}</v-list-item-title>
                <v-list-item-title class="headline mb-2"><b>{{$t('cart.delivery_cost')}}:</b> {{resumen.deliveryCost |formatCurrency }}</v-list-item-title>
                <v-list-item-title class="headline mb-2"><b>{{$t('cart.total')}}:</b> {{resumen.total | formatCurrency }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col class="mb-0" v-if="resumen.status_order.id <= 2" >
            <v-dialog v-model="dialogCancel" persistent max-width="500px" >
              <template v-slot:activator="{ on, attrs }">
                <v-btn rounded color="error" v-bind="attrs" v-on="on" >{{$t('cart.cancel_order')}}</v-btn>
              </template>
              <v-card>
                <v-card-title class="text-center align-center justify-center " >{{$t('order.qn_cancel_order')}}</v-card-title>
                <v-card-text class="pb-3">
                  <v-textarea
                    class="mt-3 "
                    prepend-icon="mdi-comment-text-outline"
                    :label=" this.$t('comment') "
                    v-model="commentCancel"
                    hint="Minimo 10 caracteres."
                    auto-grow
                    row-height="10"
                    maxlength="200"
                    counter="200"
                    outlined
                    color="pediidos">
                  </v-textarea>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="pediidos" text @click="closeDialogCancel()" >{{$t('cancel')}}</v-btn>
                  <v-btn color="pediidos" text @click="cancelOrder() "  :disabled="commentCancel.length < 10" >{{$t('confirm')}}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script type="text/javascript">
  import Api from '@/helpers/api.js';
  import { database } from '@/firebase/InitFirebase.js';
  import moment from '@/helpers/DateUtils.js';
  import Utils from '@/helpers/Utils.js';
  import { mapMutations, mapState } from 'vuex';

  export default {
    name: 'OrderDetail',
    props: ['oi','si','ui',],
    data() {
      return {
        dialog: false,
        dialogCancel: false,
        commentCancel: '',
        order_id: '',
        datesStates: null,
        detailOrder: [],
        resumen: {
          subtotal: 0,
          deliveryCost: 0,
          total: 0,
          comment: '',
          status_order: '',
          time: '',
          catalog_id: '',
          store_id: '',
        },
        client: {
          id: '',
          name: '',
          address: '',
          details: '',
          phone: '',
          email: '',
        },
        store: {
          id: '',
          name: '',
          phone: '',
          store: '',
          address: '',
          city: '',
        },
      }
    },
    filters: {
      formatDate(date) {
        return moment.formatDate(date,'YYYY-MM-DD', 'DD MMMM YYYY');
      },
      formatCurrency(currency) {
        return Utils.formatCurrency(currency);
      },
    },
    watch: {
      dialog(value) {
        if(value){
          this.loadData();
        }
      },
    },
    mounted() {},
    methods: {
      ...mapMutations(['APP_LOADER',]),
      formatDateShort(date) {
        if(date){
          return moment.formatDate(date,'YYYY-MM-DDTHH:mm', 'MMM DD, hh:mm a');
        }
        return this.$t('order.status_pending');
      },
      async loadData() {
        this.commentCancel = '';
        this.getDetailsOrder();
        this.getOrder();
        this.deleteNotify();
      },
      async getOrder() {
        let data = {
          shop_id: this.si,
          order_id: this.oi,
          user_id: this.ui
        }
        Api.post('orders/search/',data).then((result)=> {
          if(result.status < 300) {
            this.resumen.subtotal = result.data.subtotal;
            this.resumen.deliveryCost = result.data.delivery_cost;
            this.resumen.total = result.data.total;
            this.resumen.comment = result.data.comment;
            this.resumen.status_order = result.data.status_order;
            this.resumen.time = (result.time !== '0' ? result.data.time : this.$t('order.status_pending'));
            this.resumen.catalog_id = result.data.catalog_id;
            this.resumen.store_id = result.data.shop.id;
            this.resumen.date_register = result.data.date_register;
            this.resumen.date_confirm = result.data.date_confirm;
            this.resumen.date_reject = result.data.date_reject;
            this.resumen.date_end = result.data.date_end;
            this.resumen.date_cancel = result.data.date_cancel;
            this.resumen.date_canceled = result.data.date_canceled;
            if(this.resumen.status_order.id === 3) {
              this.resumen.motive_cancel = result.data.rejected_motive;
            }else if(this.resumen.status_order.id >= 5) {
              this.resumen.motive_cancel = result.data.cancel_motive;
            }else{
              this.resumen.motive_cancel = null;
            }

            this.client.id = result.data.user.id;
            this.client.name = result.data.user.first_name;
            this.client.address = result.data.user_address.address;
            this.client.lat = result.data.user_address.lat;
            this.client.lng = result.data.user_address.lon;
            this.client.details = result.data.user_address.address_detail;
            this.client.phone = result.data.user.phone;

            this.store.id = result.data.shop.id;
            this.store.idUser = result.data.shop.user.id;
            this.store.name = result.data.shop.user.first_name;
            this.store.phone = result.data.shop.phone;
            this.store.store = result.data.shop.name;
            this.store.address = result.data.shop.address;
            this.store.city = result.data.shop.city.name;

            this.getDatesStates();
          }
        }).catch((error)=> {
          console.log('This is error ',error);
          this.goBack();
        })
      },
      async getDetailsOrder() {
        let data = {
          shop_id: this.si,
          order_id: this.oi,
          user_id: this.ui
        }
        Api.post('orders/detail/',data).then((result)=> {
          if(result.status < 300) {
            this.detailOrder = result.data;
          }
        }).catch((error)=> {
          console.log('This is error ',error);
          this.goBack();
        })
      },
      async cancelOrder() {
        let data = {
          order_id: this.oi,
          message: this.commentCancel,
        }
        Api.post('orders/cancel/',data).then((result)=> {
          if(result.status < 300) {
            this.closeDialogCancel();
            this.loadData();
          }
        }).catch((error)=> {
          console.log('This is error ',error);
          this.goBack();
        })
      },
      getDatesStates() {
        let myStates = {};
        myStates['date_register'] = {
          date: this.resumen.date_register,
          description: this.$t('order.status_received'),
          background: (this.resumen.status_received !== null ? 'blue' : 'grey'),
          textTab: (this.resumen.status_received !== null ? 'mdi-check' : 'mdi-clock-outline'),
        }
        myStates['date_confirm'] = {
          date: this.resumen.date_confirm,
          description: this.$t('order.status_confirmed'),
          background: (this.resumen.date_confirm !== null ? 'green' : 'grey'),
          textTab: (this.resumen.date_confirm !== null ? 'mdi-check' : 'mdi-clock-outline'),
        }
        myStates['date_end'] = {
          date: this.resumen.date_end,
          description: this.$t('order.status_delivered'),
          background: (this.resumen.date_end !== null ? 'green' : 'grey'),
          textTab: (this.resumen.date_end !== null ? 'mdi-check' : 'mdi-clock-outline'),
        }
        if(this.resumen.date_reject !== null) {
          delete myStates.date_confirm;
          delete myStates.date_end;
          myStates['date_reject'] = {
            date: this.resumen.date_reject,
            description: this.$t('order.status_rejected'),
            background: "red",
            textTab: "mdi-close",
          }
        }
        if(this.resumen.status_order.id === 5) {
          delete myStates.date_end;
          if(this.resumen.date_confirm === null){delete myStates.date_confirm;}
          myStates['date_cancel'] = {
            date: this.resumen.date_cancel,
            description: this.$t('order.status_to_cancel'),
            background: "amber",
            textTab: "mdi-cancel",
          }
          myStates['date_canceled'] = {
            date: this.resumen.date_canceled,
            description: this.$t('order.status_to_resolve'),
            background: "grey",
            textTab: "mdi-clock-outline",
          }
        }
        if(this.resumen.status_order.id === 6) {
          delete myStates.date_end;
          if(this.resumen.date_confirm === null){delete myStates.date_confirm;}
          myStates['date_cancel'] = {
            date: this.resumen.date_cancel,
            description: this.$t('order.status_to_cancel'),
            background: "amber",
            textTab: "mdi-cancel",
          }
          myStates['date_canceled'] = {
            date: this.resumen.date_canceled,
            description: this.$t('order.status_canceled'),
            background: "red",
            textTab: "mdi-close",
          }
        }
        this.datesStates = myStates;
      },
      async deleteNotify() {
        const notify = database.ref('users/'+this.ui+'/orders/'+this.oi);
        notify.remove();
      },
      goBack(){
        this.$router.push('/orders');
      },
      closeDialogCancel() {
        this.commentCancel = '';
        this.dialogCancel = false;
      },
    },
    computed: {
      ...mapState(['appLoader','user'])
    },
  }
</script>

<style type="text/css" >
  .c-scroll::-webkit-scrollbar {
    width: 15px;
    border-radius: 15px;
  }
  .c-scroll::-webkit-scrollbar-track {
    background: #E3F2FD;
    border-radius: 15px;
  }
  .c-scroll::-webkit-scrollbar-thumb {
    background: #2196F3;
    border-radius: 15px;
  }
  .c-scroll::-webkit-scrollbar-thumb:hover {
    background: #2c6add;
  }
</style>